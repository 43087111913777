<template>
  <div v-if="plugin" class="plugin-card group flex py-6">
    <c-link
      :to="'/' + plugin.handle"
      :title="plugin.name + ' - ' + plugin.shortDescription"
    >
      <div class="plugin-icon mr-4 w-16 flex-shrink-0">
        <img
          v-if="plugin.iconUrl"
          :alt="plugin.name + ' icon'"
          class="w-full"
          :src="plugin.iconUrl"
        />
        <img
          v-else
          alt="Default plugin icon"
          class="w-full"
          src="../assets/images/default-plugin.svg"
        />
      </div>
    </c-link>

    <div class="details leading-tight flex-1 min-w-0">
      <c-link
        v-if="plugin"
        class="no-underline text-black dark:text-white cursor-pointer"
        :to="'/' + plugin.handle"
        :title="plugin.name + ' - ' + plugin.shortDescription"
      >
        <div class="details-header overflow-hidden leading-normal">
          <div class="name font-bold mb-1 group-hover:text-interactive-link">
            {{ plugin.name }}
          </div>
          <div class="description">
            {{ plugin.shortDescription }}
          </div>
        </div>
      </c-link>

      <template v-if="plugin.totalReviews && plugin.totalReviews > 0 && plugin.ratingAvg">
        <CLink
          class="flex items-center gap-1 text-sm mt-2 no-underline text-black dark:text-white cursor-pointer"
          :to="{name: 'handle-reviews', params: {handle: plugin.handle}}"
        >
          <RatingStars :rating="plugin.ratingAvg" size="sm" />
          ({{ plugin.totalReviews }})
        </CLink>
      </template>

      <div class="mt-2">
        <template v-if="plugin.abandoned">
          <div class="text-red-600">Abandoned</div>
        </template>
        <template v-else>
          <div class="price">
            {{ getPriceRangeLabel(plugin) }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {useHelpers} from '~/composables/useHelpers';
  import type {CraftPlugin} from '~';

  const {getPriceRangeLabel} = useHelpers();

  defineProps<{
    plugin: CraftPlugin;
  }>();
</script>

<style lang="postcss">
  .plugin-card {
    @apply flex no-underline text-black dark:text-white;

    .plugin-icon {
      @apply mr-4;
      width: 60px;
      flex-shrink: 0;

      img {
        width: 100%;
      }
    }

    .details {
      @apply leading-tight;
      flex-grow: 1;
      min-width: 0;

      .details-header {
        @apply overflow-hidden leading-normal;
        max-height: 4.75em;

        .name {
          @apply font-bold mb-1;
        }
      }

      .price {
        @apply text-gray-500 dark:text-gray-400;
      }

      .name:hover {
        color: #0d78f2;
      }
    }
  }
</style>
