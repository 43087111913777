<template>
  <div>
    <div
      v-if="plugins.length > 0"
      class="grid-plugins grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8"
    >
      <div
        v-for="(plugin, key) in plugins"
        :key="key"
        class="grid-box border-b dark:border-gray-700"
        :class="{
          'hidden lg:block': autoLimit && key >= 4,
          'lg:hidden': autoLimit && key >= 6,
        }"
      >
        <plugin-card :plugin="plugin" />
      </div>
    </div>
  </div>
</template>

<script>
  import PluginCard from './PluginCard';

  export default {
    components: {
      PluginCard,
    },

    props: {
      plugins: {
        type: Array,
        default: () => [],
      },
      autoLimit: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        winWidth: null,
      };
    },

    mounted() {
      this.winWidth = window.innerWidth;
      window.addEventListener('resize', this.onWindowResize);
    },

    beforeUnmount() {
      window.removeEventListener('resize', this.onWindowResize);
    },

    methods: {
      showPlugin(plugin) {
        this.$router.push({path: '/' + plugin.id});
      },

      onWindowResize() {
        this.winWidth = window.innerWidth;
      },
    },
  };
</script>
